<template>
  <div>
    <Table
      label="Referral"
      :actions="actions"
      :items="$store.getters['referral/tableData']"
      :fields="fields"
      :loading="$store.state.referral.loadingTable"
      :filter="$store.state.referral.tableFilter"
      :keyword="$store.state.referral.tableKeyword"
      :pagination="$store.state.referral.tablePagination"
      :sort="$store.state.referral.tableSort"
      :sort-options="sortOptions"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Sort
      sortOptions: [],

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'nickname',
          label: 'Referee',
          type: 'profile',
          image: '',
        },
        {
          key: 'referred_by_nickname',
          label: 'Referral',
          type: 'profile',
          image: 'referred_by_avatar',
        },
        {
          key: 'referralFinished_status',
          label: 'Status',
          type: 'dot',
          map: {
            successful: 'success',
            pending: 'danger',
          },
          filter: {
            key: 'finished',
            type: 'select',
            options: [
              { value: false, text: 'Pending' },
              { value: true, text: 'Succeeded' },
            ],
          },
        },
        {
          key: 'user_created_at',
          label: 'Created at',
          type: 'datetime',
        },
        {
          key: 'referral_finished_at',
          label: 'Reffered at',
          type: 'datetime',
        },
      ],

      actions: ['view'],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('referral/getTableData')
    },
    filterData(val) {
      this.$store.commit('referral/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('referral/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('referral/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('referral/SET_TABLE_KEYWORD', val)
    },
  },
}
</script>
